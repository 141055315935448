<template>
  <a-select
    :class="selectClasses"
    :style="selectStyles"
    :size="size"
    @select="$emit('select', $event)"
    :defaultValue="defaultValue"
    :loading="loading"
    :placeholder="placeholder"
    :disabled="disabledSelect"
  >
    <a-select-option
      v-for="{ label, value, id } in list"
      :key="id"
      :value="value"
      :disabled="disabled(label)"
    >
      {{ label }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    visibility: {
      type: Boolean,
      default: true,
    },
    selectClasses: String,
    selectStyles: String,
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
    size: {
      type: String,
      default: "small",
    },
    vModel: String,
    disabledOptionField: String,
    placeholder: String,
    defaultValue: String,
    loading: Boolean,
    disabledSelect: {
      type: Boolean,
      default: false,
    }
  },
  computed: {

  },
  methods: {
    disabled(label) {
      return this.disabledOptionField && this.disabledOptionField.toLowerCase() === label.toLowerCase();
    },
  },
};
</script>