<template>
  <div class="bookings">
    <div style="justify-content: center; display: flex;">
      <a-button
        type="primary"
        size="large"
        class="btn_create_booking"
        :disabled="!rootState.userInfo.hasCompany || (!state.bookings.length && this.isBookingsLoading)"
        @click="$router.push({name: 'NewBooking'})"
      >
        Boeking aanmaken
      </a-button>
    </div>
    <h1>Je boekingen</h1>
    <div class="searchbar">
      <a-input-search
        v-model.trim="state.searchFilter.searchString"
        placeholder="Search bookings..."
        enter-button="Search"
        size="large"
        :loading="this.isFilteredBookingListLoading"
        @search="onSearch"
        :disabled="this.isBookingsLoading || this.isFilteredBookingListLoading"
      />
    </div>
    <div>
      <div class="header">
        <div class="header__item">
          Boekingsreferentie
        </div>
        <div class="header__item">
          Persoonlijke referentie
        </div>
        <div class="header__item">
          Boekingsdatum
        </div>
        <div class="header__item">
          <a-select
            id="statusSelect"
            v-model="state.searchFilter.bookingStatus"
            @select="onSearch"
            style="width: 80%; background: transparent"
            :loading="this.isFilteredBookingListLoading"
            :disabled="this.isBookingsLoading || this.isFilteredBookingListLoading"
          >
            <a-select-option 
              v-for="status in statusList" :key="status.id"
            >
              {{ status.label }}
            </a-select-option>
          </a-select>
          <label 
            for="statusSelect"
            class="header__form--label"
            :class="bookingStatus ? 'focused' : ''"
          >
            Status
          </label>
        </div>
        <div class="header__item">
          Prijs per kilogram / CBM
        </div>
        <div class="header__item">
          Verwachte leverdatum
        </div>
      </div>
      <div class="table">
        <BookingItem
          v-for="booking in state.bookings"
          :key="booking.id"
          :state="booking"
        />
      </div>
    </div>
    <a-empty
      v-if="!state.bookings.length && !this.isBookingsLoading"
      description="Er zijn nog geen boekingen aangemaakt"
    />
    <CustomPagination
      v-if="isAnyBookingExists"
      showSizeChanger
      :totalItems="totalItems"
      :itemPerPage="itemPerPage"
      :currentPage="currentPage"
      @changePage="paginationHandler"
      @itemPerPageHandler="itemPerPageHandler"
    />
    <CustomSpinner 
      v-if="this.isBookingsLoading || this.isFilteredBookingListLoading"
      size="100" 
      :centered="!isAnyBookingExists"
      :bottomed="isAnyBookingExists"
    />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import BookingItem from "@/components/dashboard/BookingItem";
import CustomPagination from "@/components/customComponents/CustomPagination";
import CustomSpinner from "@/components/customComponents/CustomSpinner";

export default {
  name: "Bookings",
  components: {
    BookingItem,
    CustomPagination,
    CustomSpinner
  },
  data() {
    return {
      statusList: [
        {
          label: 'Alle statussen',
          id: 0,
        },
        {
          label: 'Aanmelding',
          id: 1,
        },
        {
          label: 'Verzameling',
          id: 2,
        },
        {
          label: 'Ontvangen',
          id: 3,
        },
        {
          label: 'Klaar voor vertrek',
          id: 4,
        },
        {
          label: 'Verzending',
          id: 5,
        },
        {
          label: 'Aangekomen in Nederland',
          id: 10,
        },
        {
          label: 'Inklaring',
          id: 6,
        },
        {
          label: 'Levering',
          id: 7,
        },
        {
          label: 'Betaling',
          id: 8,
        }
      ],
      defaultLoadingPageParams: {
        currentPage: 1, 
        itemsPerPage: 10
      }
    }
  },
  created() {
    this.getBookings(this.defaultLoadingPageParams)
  },
  beforeDestroy() {
    this.clearBookingsList();
    this.clearSearchFilter();
  },
  computed: {
    ...mapState({
      state: state => state.dashboardMain,
      rootState: state => state,
      isBookingsLoading: state => state.dashboardMain.isBookingsLoading,
      isFilteredBookingListLoading: state => state.dashboardMain.isFilteredBookingListLoading,
    }),
    bookingStatus () {
      return this.state.searchFilter.bookingStatus !== '';
    },
    totalItems () {
      return this.state.pagination.totalItems;
    },
    itemPerPage () {
      return this.state.pagination.itemsPerPage;
    },
    currentPage () {
      return this.state.pagination.currentPage;
    },
    isSearchFilterEmpty () {
      return !(this.state.searchFilter.bookingStatus || this.state.searchFilter.searchString);
    },
    isAnyBookingExists () {
      return !!(this.state.bookings.length);
    },
  },
  methods: {
    ...mapActions([
      "getBookings",
      "searchBooking"
    ]),
    ...mapMutations([
      "clearBookingList", 
      "updatePagination", 
      "clearBookingsList", 
      "clearSearchFilter", 
    ]),
    onSearch() {
      this.isAnyBookingExists  
        ? this.searchBooking({ 
          currentPage: this.defaultLoadingPageParams.currentPage, 
          itemsPerPage: this.itemPerPage 
        })
          .catch(() => this.getBookings(this.defaultLoadingPageParams))
        : this.searchBooking(this.defaultLoadingPageParams)
          .catch(() => this.getBookings(this.defaultLoadingPageParams));
    },
    fetchItems ({ currentPage, itemsPerPage }) {
      this.isSearchFilterEmpty 
        ? (this.getBookings({ currentPage, itemsPerPage })
            .then(() => window.scrollTo({ top: 0, behavior: 'smooth'})))
            .catch(e => console.log('Search booking failed', e))
        : (this.searchBooking({ currentPage, itemsPerPage })
            .then(() => window.scrollTo({ top: 0, behavior: 'smooth'})))
            .catch(e => console.log('Search booking failed', e))
    },
    paginationHandler ({ currentPage, itemsPerPage }) {
      this.fetchItems({ currentPage, itemsPerPage });
    },
    itemPerPageHandler ({ currentPage, itemsPerPage }) {
      this.fetchItems({ currentPage, itemsPerPage });
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  padding: 12px;

  &__form--label {
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 10px;
    line-height: 1;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    &.focused {
      top: -8px;
      background: #fafaff;
      padding-left: 2px;
      padding-right: 2px;
      font-size: 14px;
      color: #FFA300;
      z-index: 2;
    }
  }
}

.header__item {
  width: calc(100% / 6);
  padding-right: 25px;
  font-weight: 600;
  position: relative;
}
.searchbar {
  margin-bottom: 20px;
}

.table {
  position: relative;
  background: white;
}
::v-deep .ant-empty {
  padding-top: 100px;
}
</style>
