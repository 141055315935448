<template>
  <div>
    <div class="row"
         @click="clickHandler"
    >
      <div class="row__item">
        {{ state.uuid || '-' }}
      </div>
      <div class="row__item">
        <a-form-item
          :validate-status="personalRefValidateState"
        >
          <a-input-search
            :loading="isChangingPersonalRef"
            :disabled="isChangingPersonalRef"
            size="large"
            class="edited-input"
            v-model="state.personal_ref"
            @focus="setPersonalRefValueHandler"
            @change.native="updatePersonalRefValueHandler"
            @click.stop
          />
        </a-form-item>
      </div>
      <div class="row__item">
        {{new Date(state.created_at).toLocaleDateString("ru-RU")}}
      </div>
      <div class="row__item">
        <div class="row__status">
          {{ state.status.name || '-' }}
        </div>
      </div>
      <div class="row__item">
        {{ state.main_price || '-' }}
      </div>
      <div class="row__item" v-if="state.delivery_date">
        {{ new Date(state.delivery_date).toLocaleDateString("ru-RU")}}
      </div>
      <div v-else class="row__item">
        &mdash;
      </div>
    </div>
    <div
      class="bookingInfo"
      :class="{show: isOpen}"
      ref="bookingHeight"
    >
      <div class="progress">
        <BookingProgressStep
          v-for="(obj, idx) in steps"
          :key="obj.id"
          :step="idx + 1"
          :isGrey="steps.findIndex(step => step.id === state.status_id) < idx"
          :title="obj.title"
          :text="obj.text"
          :imgUrl="obj.imgUrl"
        />
      </div>
      <div 
        class="additional-params additional-params__wrapper"
        :class="{'additional-params__wrapper--centered': !isChangeTransportTypeDisabled}"
      >
        <div
          v-if="isChangeTransportTypeDisabled"
          class="transportType"
        >
          <div style="width: 25%">
            <span class="transportType__title">Transport type wijzigen:</span>
            <CustomSelect
              selectStyles="width: 80%; background: transparent"
              :list="transportType"
              :disabledOptionField="transportTypeName"
              :defaultValue="transportTypeName"
              @select="(value) => deliveryInfoHandler('transportTypeIdToChange', value)"
            />
          </div>

          <div style="width: 25%">
            <span class="transportType__title">Afgesproken Incoterm:</span>
            <CustomSelect
              selectStyles="width: 80%; background: transparent"
              :list="incotermsRuleOptions"
              :placeholder="placeholderForIncotermsRuleSelect"
              @select="(value) => deliveryInfoHandler('incotermsRuleIdToChange', value)"
              :disabledSelect="!transportTypeIdToChange"
            />
          </div>

          <CustomBtn
            title="Gegevens opslaan"
            :loading="isChangingDeliveryInfo"
            @click="updateDeliveryInfoHandler"
            :disabled="isChangingDeliveryInfo || thereIsNoDeliveryInfoToChange"
          />
        </div>
        <transition name="slide-fade">
          <div 
            v-if="isShipDelivery"
            class="ship"
            :class="{'ship--centered': isChangeTransportTypeDisabled}"
          >
            <div>
              <span class="ship__title">Naam boot: </span> 
              <span class="ship__name">{{ state.booking_batches[0].ship_info }}</span>
            </div>
            <a-tooltip 
              placement="right"
              class="ship-link"
            >
              <a 
                href="https://www.vesselfinder.com/" 
                target="_blank" 
                style="margin-right: 5px"
              >
                www.vesselfinder.com
              </a>
              <template slot="title">
                <h3 style="color: #fff">
                  Door op de link te klikken en de naam van de boot waarop jouw goederen liggen in te 
                  vullen kan jij precies inzien waar de boot zich momenteel bevindt.
                </h3>
              </template>
              <a-icon type="info-circle" style="color: black"/>
            </a-tooltip>
          </div>
        </transition>
      </div>
      <a-collapse
        expand-icon-position="right"
        style="background-color: transparent !important"
      >
        <a-collapse-panel
          v-if="state.supplier"
          key="1"
          header="Gegevens leverancier"
          class="antdCollapse"
          style="background-color: white"
        >
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Afgesproken Incoterm
            </span>
            <span class="collapsePanel__text">
              {{ incotermsRuleName || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Bedrijfsnaam
            </span>
            <span class="collapsePanel__text">
              {{ state.supplier.company_name || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              E-mailadres
            </span>
            <span class="collapsePanel__text">
              {{ state.supplier.email || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Telefoonnummer
            </span>
            <span class="collapsePanel__text">
              {{ state.supplier.phone || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Straat
            </span>
            <span class="collapsePanel__text">
                {{ state.supplier.address.street || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Postcode
            </span>
            <span class="collapsePanel__text">
              {{ state.supplier.address.postcode || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Plaats
            </span>
            <span class="collapsePanel__text">
              {{ state.supplier.address.city || '-' }}
            </span>
          </div>
        </a-collapse-panel>
        <a-collapse-panel
          key="2"
          header="Product(en)"
          class="antdCollapse"
          style="background-color: white"
        >
          <div
            class="collapsePanel__product"
            v-for="(object, key) in state.products"
            :key="key"
          >
            <h2>Product {{ key + 1 }}</h2>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                Omschrijving
              </span>
              <span class="collapsePanel__text">
                {{ object.description || '-' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                HS-Code
              </span>
              <span class="collapsePanel__text">
                {{ object.hs_code || '-' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                EAN-Code
              </span>
              <span class="collapsePanel__text">
                {{ object.ean_code || '-' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                Prijs per stuk in USD
              </span>
              <span class="collapsePanel__text">
                {{ object.price_per_piece || '-' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                Overige kosten in USD
              </span>
              <span class="collapsePanel__text">
                {{ object.other_costs || '-' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                Hoeveelheid producten
              </span>
              <span class="collapsePanel__text">
                {{ object.quantity_in_booking || '-' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                Aantal dozen
              </span>
              <span class="collapsePanel__text">
                {{ object.quantity_boxes || '-' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                Brutogewicht
              </span>
              <span class="collapsePanel__text">
                {{ object.gross_weight || '-' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                CBM
              </span>
              <span class="collapsePanel__text">
                {{ object.cbm || '-' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                Is gevaarlijk?
              </span>
              <span class="collapsePanel__text">
                {{ object.is_dangerous ? 'Ja' : 'Nee' }}
              </span>
            </div>
            <div class="collapsePanel">
              <span class="collapsePanel__text">
                Sub Products
              </span>
              <span class="collapsePanel__text">
                {{ object.product_sub
                  ? (object.product_sub.length ? object.product_sub.length : '-')
                  : 'No information' 
                }}
              </span>
            </div>
            <div class="brd">
              <h3 class="collapsePanel__text" style="font-weight: 500">
                Totale waarde in USD
              </h3>
              <h3 class="collapsePanel__text">
                ${{ object.total_value || '-' }}
              </h3>
            </div>

          </div>
          <div class="totalForAllProducts">
            <span>
              Totale waarde alle producten
            </span>
            <span>
              ${{state.products_sum_total_value}}
            </span>
          </div>
        </a-collapse-panel>
        <a-collapse-panel
          key="3"
          v-if="state.delivery"
          header="Levering"
          class="antdCollapse"
          style="background-color: white"
        >
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              LVB-levering
            </span>
            <span class="collapsePanel__text">
              {{ state.delivery.lvb ? 'Ja' : 'Nee' }}
            </span>
          </div>
          <div class="collapsePanel" v-if="state.delivery.lvb_location">
            <span class="collapsePanel__text">
              LVB-gegevens
            </span>
            <span class="collapsePanel__text">
              Stad: {{ state.delivery.lvb_location.city }}; Land: {{ state.delivery.lvb_location.country }}; Postcode: {{ state.delivery.lvb_location.postcode }}; Straat:
              {{ state.delivery.lvb_location.street }}; Huisnummer: {{ state.delivery.lvb_location.house_number }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Verzekering DHL
            </span>
            <span class="collapsePanel__text">
              €{{ state.delivery.dhl_insurance || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Bedrijfsnaam
            </span>
            <span class="collapsePanel__text">
              {{ state.delivery.company_name || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Contactpersoon
            </span>
            <span class="collapsePanel__text">
              {{ state.delivery.contact || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Straat
            </span>
            <span class="collapsePanel__text">
              {{ state.delivery.address.street || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Plaats
            </span>
            <span class="collapsePanel__text">
              {{ state.delivery.address.place || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Postcode
            </span>
            <span class="collapsePanel__text">
              {{ state.delivery.address.postcode || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Land
            </span>
            <span class="collapsePanel__text">
              {{ state.delivery.address.country || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Toevoeging
            </span>
            <span class="collapsePanel__text">
              {{ state.delivery.address.addition || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Huisnummer
            </span>
            <span class="collapsePanel__text">
              {{ state.delivery.address.house_number || '-' }}
            </span>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>
<script>
import BookingProgressStep from "@/components/dashboard/BookingProgressStep";
import firstImg from "@/assets/dashboard/booking_created.svg"
import secondImg from "@/assets/dashboard/arrival_at_depot.svg"
import thirdImg from "@/assets/dashboard/shipped.svg"
import fourthImg from "@/assets/dashboard/clearance.svg"
import fifthImg from "@/assets/dashboard/delivery.svg"
import sixthImg from "@/assets/dashboard/payment.svg"
import all from "@/assets/dashboard/all.svg"
import seventhImg from "@/assets/dashboard/warehouseChina.png"
import CustomSelect from "@/components/customComponents/CustomSelect"
import CustomBtn from "@/components/customComponents/CustomBtn";
import {mapActions, mapState, mapMutations} from "vuex";
import { maxLength } from "vuelidate/lib/validators";
import CustomIcon from "../customComponents/CustomIcon.vue";
import CustomModalWindow from "../customComponents/CustomModalWindow.vue";

export default {
  props: {
    state: Object
  },
  components: {
    BookingProgressStep,
    CustomSelect,
    CustomBtn,
    CustomIcon,
    CustomModalWindow,
  },
  data() {
    return {
      isOpen: false,
      isBookingCanceling: false,
      steps: [
        {
          imgUrl: firstImg,
          title: 'Aanmelding',
          text: 'De goederen zijn aangemeld en worden gecontroleerd',
          id: 1,
        },
        {
          imgUrl: secondImg,
          title: 'Verzameling',
          text: 'De goederen zijn geaccepteerd en worden verzameld in ons warenhuis',
          id: 2,
        },
        {
          imgUrl: seventhImg,
          title: 'Ontvangen',
          text: 'We hebben de goederen ontvangen in ons warenhuis',
          id: 8,
        },
        {
          imgUrl: null,
          title: null,
          text: 'De goederen zijn aangekomen bij de haven en zijn klaar voor vertrek. Binnen enkele dagen zullen jouw goederen vertrokken zijn vanuit China!',
          id: 9,
        },
        {
          imgUrl: thirdImg,
          title: 'Verzending',
          text: 'We verzenden je goederen naar Nederland',
          id: 3,
        },
        {
          imgUrl: null,
          title: null,
          text: 'De goederen zijn aangekomen in Nederland, binnen enkele dagen zullen jouw goederen ingeklaard en vervolgens geleverd worden',
          id: 10,
        },
        {
          imgUrl: fourthImg,
          title: 'Inklaring',
          text: 'Je goederen worden ingeklaard',
          id: 4,
        },
        {
          imgUrl: fifthImg,
          title: 'Levering',
          text: 'Je goederen worden afgeleverd',
          id: 5,
        },
        {
          imgUrl: sixthImg,
          title: 'Betaling',
          text: 'Je ontvangt je factuur en kunt betalen',
          id: 6,
        }
      ],
      transportType: [
        {
          id: 1,
          label: 'Luchtvracht',
          value: 1,
          incotermsRule: [
            {
              id: 1,
              label: 'EXW',
              value: 1,
            },
            {
              id: 2,
              label: 'FCA',
              value: 2,
            },
          ]
        },
        {
          id: 2,
          label: 'Zeevracht',
          value: 2,
          incotermsRule: [
            {
              id: 1,
              label: 'EXW',
              value: 1,
            },
            {
              id: 2,
              label: 'FCA',
              value: 2,
            },
            {
              id: 3,
              label: 'FOB',
              value: 3,
            },
          ]
        },
      ],
      transportTypeIdToChange: '',
      incotermsRuleIdToChange: '',
      isChangingPersonalRef: false,
      initPersonalRefValue: '',      
    }
  },
  created() {
    this.transportTypeName === "Zeevracht" ? this.steps[4].imgUrl = all : this.steps[4].imgUrl = thirdImg;
  },
  beforeDestroy() {
    this.clearPersonalRefError();
  },
  computed: {
    ...mapState({
      rootState: state => state,
    }),
    isChangeTransportTypeDisabled () {
      return this.state.status_id === 1 || this.state.status_id === 2;
    },
    transportTypeName () {
      return this.state.transport_type.name;
    },
    incotermsRuleName () {
      const rule = this.state.incoterm_id;
      if (rule === 1) return 'EXW';
      if (rule === 2) return 'FCA';
      if (rule === 3) return 'FOB';
    },
    placeholderForIncotermsRuleSelect () {
      return this.transportTypeIdToChange ? "Kies Incoterms-regel" : this.incotermsRuleName;
    },
    incotermsRuleOptions () {
      return this.transportType.find(({ label }) => label !== this.transportTypeName).incotermsRule;
    },
    isShipDelivery () {
      return (this.state.transport_type.name === "Zeevracht" && !!this.state.booking_batches.length && !!this.state.booking_batches[0].ship_info);
    },
    isChangingDeliveryInfo () {
      return this.rootState.dashboardMain.isChangingDeliveryInfo;
    },
    thereIsNoDeliveryInfoToChange () {
      return this.transportTypeIdToChange === '' || this.incotermsRuleIdToChange === '';
    },
    personalRefValidateState() {
      return this.$v.state.personal_ref.$invalid ? 'error' : 'success';
    },
  },
  methods: {
    ...mapActions([
        'getBookings',
        'cancelBooking',
        'changeDeliveryInfo',
        'updatePersonalRef',
      ]),
    ...mapMutations(['clearPersonalRefError', 'manualUpdatePersonalRef']),
    clickHandler() {
      this.isOpen = !this.isOpen
      let elem = this.$refs.bookingHeight
      let scrollHeight = this.$refs.bookingHeight.scrollHeight
      if (this.isOpen) {
        elem.style.height = scrollHeight + 'px'
        setTimeout(() => elem.style.height = 'auto', 300)
      } else {
        elem.style.height = 0
      }
    },
    cancelBookingHandler() {
      this.isBookingCanceling = true
      let id = this.state.id
      this.cancelBooking(id).then(e => e.ok ? this.getBookings() : null).finally(() => this.isBookingCanceling = false)
    },
    deliveryInfoHandler (fieldName, value) {
      this[fieldName] = value;
    },
    clearDeliveryInfo () {
      this.transportTypeIdToChange = '';
      this.incotermsRuleIdToChange = '';
    },
    updateDeliveryInfoHandler () {
      const bookingId = this.state.id;
      const transportTypeId = this.transportTypeIdToChange;
      const incotermRuleId = this.incotermsRuleIdToChange;

      if (!transportTypeId || !incotermRuleId) return;

      const options = {
        bookingId,
        transportTypeId,
        incotermRuleId,
      };

      this.changeDeliveryInfo(options)
        .then(() => this.clearDeliveryInfo());
    },
    toggleLoadingStatusPersonalRef () {
      this.isChangingPersonalRef = !this.isChangingPersonalRef;
    },
    setPersonalRefValueHandler ({ target }) {
      this.initPersonalRefValue = target.value.trim();
    },
    updatePersonalRefValueHandler ({ target }) {
      const bookingId = this.state.id;
      const prevPersonalRefValue = this.initPersonalRefValue.trim();
      const currentPersonalRefValue = target.value;
      if (this.$v.state.personal_ref.$invalid || prevPersonalRefValue === currentPersonalRefValue) {
        this.manualUpdatePersonalRef({ bookingId, personalRef: prevPersonalRefValue })
        return;
      }
      this.toggleLoadingStatusPersonalRef();

      this.updatePersonalRef({bookingId, personal_ref: currentPersonalRefValue})
        .then(() => this.toggleLoadingStatusPersonalRef())
        .catch(() => this.toggleLoadingStatusPersonalRef())
        .finally(() => this.initPersonalRefValue = '')
    },
  },
  validations() {
    return {
      state: {
        personal_ref: {
          maxLength: maxLength(25),
        }
      }
    }
  },
  name: "BookingItem"
}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  align-items: center;
  padding: 20px 12px;
  border-bottom: 1px solid #e0e0e0;
  transition: .3s;
  user-select: none;

  &:hover {
    background: rgba(255, 140, 90, 0.09);
    cursor: pointer;
    transition: .3s;
  }
}

.row__item {
  width: calc(100% / 6);
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;

  .edited-input{
    width: auto;
    background: transparent;
    margin-right: 15px;
  }
}

.row__status {
  padding: 6px 10px;
  border-radius: 20px;
  background: #e0e0e0;
  display: inline;
}

.bookingInfo {
  height: 0;
  transition: .3s;
  overflow: hidden;
}

.bookingInfo.show {
  transition: .3s;
}
.bookingButtons {
  display: flex;
  padding: 10px;
  margin: 10px 40px 0 10px;
  justify-content: flex-end;
}

.progress {
  position: relative;
  display: flex;
  justify-content: center;
}

.transportType{
  display: flex;
  flex-direction: row;
  width: 70%;
  align-items: flex-end;
  &__title{
    font-size: 14px;
    line-height: 1.7;
    color: #263238;
  }
}
.ship{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title{
    font-size: 14px;
    line-height: 1.7;
    color: #263238;
  }
  &__name{
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8;
    color: #71797e;
  }
  &--centered {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.additional-params {
  padding: 20px 12px;
  &__wrapper{    
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    &--centered {
      justify-content: center;
    }
  }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
::v-deep .ant-form-item {
  margin: 0;
}
</style>
