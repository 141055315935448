<template>
  <a-button
    :html-type="htmlType"
    :type="type"
    :loading="loading"
    :disabled="disabled"
    :size="size"
    @click="$emit('click', $event)"
  >
    <slot></slot>
    {{ title }}
  </a-button>
</template>

<script>
export default {
  name: "CustomBtn",
  props: {
    htmlType: {
      type: String,
      default: "button",
    },
    type: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: "Button",
    },
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
  },
};
</script>